import * as React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ValidationErrors } from "../../components/ValidationErrors";
import { useState } from "react";
import { DatePicker } from "../../components/DatePicker";
import formatISO from "date-fns/formatISO";
import { ContractExport, useContractsExport } from "../../service/contracts";
import { AccountExport, useAccountsExport } from "../../service/accounts";
import CsvDownloader from "react-csv-downloader";
import omit from "lodash/omit";
import isString from "lodash/isString";
import { useNotifications } from "../../notifications";
import { ContentBox } from "../../components/ContentBox";
import { useServiceResourceExportPeriod, useServiceResourceExportDirect } from "../../service/api";
import LoadingButton from "@mui/lab/LoadingButton";

interface FormFields {
  date: string;
  stateDate: string;
}

type ARRFields = {
  ARR_EUR: string;
  ARR_CHF: string;
  ARR_USD: string;
  ARR_GBP: string;
  ARR_JPY: string;
  ARR_PLN: string;
};
type MRRFields = {
  MRR_EUR: string;
  MRR_CHF: string;
  MRR_USD: string;
  MRR_GBP: string;
  MRR_JPY: string;
  MRR_PLN: string;
};
type ExportedContract = Omit<ContractExport, "mcv" | "dcv" | "arr" | "mrr" | "documents" | "createTs" | "updateTs"> & {
  SF_Account: string | null;
  SF_Account_Vertical: string | null;
} & ARRFields &
  MRRFields & { acv: string; tcv: string };
type ExportedAccount = {
  id: string;
  name: string;
  vertical: string;
  isEnterprise: boolean | null;
} & ARRFields &
  MRRFields;

interface ExportRecognizedRevenueFormFields {
  startDate: string;
  endDate: string;
}
type RecognizedRevenueFields = {
  accountName: string;
  accountVertical: string;
  accountIsEnterprise: boolean | null;
  RecognizedRevenue_EUR: string;
  RecognizedRevenue_CHF: string;
  RecognizedRevenue_USD: string;
  RecognizedRevenue_GBP: string;
  RecognizedRevenue_JPY: string;
  RecognizedRevenue_PLN: string;
};
type ExportedRecognizedRevenue = Omit<
  ContractExport,
  "acv" | "tcv" | "mcv" | "dcv" | "arr" | "mrr" | "documents" | "createTs" | "updateTs"
> &
  RecognizedRevenueFields;

type ExportedRecognizedRevenueAccount = {
  accountId: string;
  accountNumber: string;
  contractingEntity: string;
  deliveryCountry: string;
  tcv: string;
  RecognizedRevenue_EUR: string;
  RecognizedRevenue_CHF: string;
  RecognizedRevenue_USD: string;
  RecognizedRevenue_GBP: string;
  RecognizedRevenue_JPY: string;
  RecognizedRevenue_PLN: string;
  accountName: string;
  accountVertical: string;
  accountIsEnterprise: boolean | null;
};

type ExportedActiveSubscription = {
  isCanceled: boolean;
  isRunning: boolean;
  accountName: string;
  accountVertical: string;
  accountIsEnterprise: boolean | null;
};

// Counter is a tuple representing [current, max]
type Counter = [number, number];

function computeTotalProgress(...counters: Counter[]): number {
  /*
  Measures the progress of N Counters -> if Counter1 is filled at 75% and Counter2 at 25%, total progress is 50%.
  */
  return counters.map((c) => c[0] / c[1]).reduce((a, b) => a + b, 0) / counters.length;
}

function ensureEscapedStrings(data: any): any {
  // The CSVDownloader cannot properly escape strings. Each quote character should be escaped using another quote character.
  return data.map((row: any) =>
    Object.fromEntries(
      Object.entries(row).map(([key, value]) => [key, isString(value) ? value.replaceAll('"', '""') : value])
    )
  );
}

function ExportARRMRR(): JSX.Element {
  const { control, handleSubmit, setValue } = useForm<FormFields>({
    defaultValues: {
      date: formatISO(new Date(), { representation: "date" }),
      stateDate: formatISO(new Date(), { representation: "date" }),
    },
  });
  const { addNotification } = useNotifications();
  const { export: contractsExport } = useContractsExport();
  const { export: accountsExport } = useAccountsExport();
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);
  const [progress, setProgress] = useState<number | null>(null);
  const [finalAccountsData, setFinalAccountsData] = useState<ExportedAccount[] | null>(null);
  const [finalContractsData, setFinalContractsData] = useState<ExportedContract[] | null>(null);

  const submitExport: SubmitHandler<FormFields> = async (data: FormFields) => {
    // TODO: error handlng
    setProgress(0.0);
    setFinalContractsData(null);
    setFinalAccountsData(null);
    const accountsPagesCounter: Counter = [0, 1];
    const contractsPagesCounter: Counter = [0, 1];
    const accountsData: ExportedAccount[] = [];
    const contractsData: ExportedContract[] = [];

    const updateProgress = () => {
      const totalProgress = computeTotalProgress(accountsPagesCounter, contractsPagesCounter);
      //  (accountsProgress[0] / accountsProgress[1] + contractsProgress[0] / contractsProgress[1]) / 2;
      setProgress(totalProgress == 1.0 ? null : totalProgress);
      if (totalProgress == 1.0) {
        addNotification({
          level: "success",
          text: "All files generated successfully",
        });
      }
    };

    const getARRMRRFields = (obj: AccountExport | ContractExport): ARRFields & MRRFields =>
      ({
        ...Object.fromEntries(Object.keys(obj.arr).map((currency) => [`ARR_${currency}`, (obj.arr as any)[currency]])),
        ...Object.fromEntries(Object.keys(obj.mrr).map((currency) => [`MRR_${currency}`, (obj.mrr as any)[currency]])),
      } as ARRFields & MRRFields);

    contractsExport(data.date, data.stateDate, async (objects: ContractExport[], final, page, pages) => {
      contractsPagesCounter[1] = pages;
      objects.forEach((obj) => {
        contractsData.push({
          ...omit(obj, ["arr", "mrr", "documents", "createTs", "updateTs", "mcv", "dcv"]),
          ...getARRMRRFields(obj),
        } as ExportedContract);
      });
      contractsPagesCounter[0] = page;
      updateProgress();
      if (final) {
        setFinalContractsData(contractsData);
        addNotification({
          level: "info",
          text: "Contracts export ready, use the button to download the file",
        });
      }
    });

    accountsExport(data.date, data.stateDate, async (objects: AccountExport[], final, page, pages) => {
      accountsPagesCounter[1] = pages;
      objects.forEach((obj) => {
        accountsData.push({
          ...omit(obj, ["arr", "mrr"]),
          ...getARRMRRFields(obj),
        });
      });
      accountsPagesCounter[0] = page;
      updateProgress();
      if (final) {
        setFinalAccountsData(accountsData);
        addNotification({
          level: "info",
          text: "Accounts export ready, use the button to download the file",
        });
      }
    });
  };

  return (
    <>
      <ContentBox header="Export ARR/MRR data">
        {errors && <ValidationErrors title="Export error" errors={errors} />}

        <Box component="form" onSubmit={handleSubmit(submitExport)} sx={{ mt: 3 }}>
          <Grid container spacing={4}>
            <Grid item xs={7}>
              <DatePicker
                disabled={progress != null}
                rules={{
                  required: true,
                }}
                helperText="Date for which the ARR/MRR needs to be calculated"
                label="Date"
                control={control}
                name="date"
              />
            </Grid>
            <Grid item xs={7}>
              <DatePicker
                disabled={progress != null}
                rules={{
                  required: true,
                }}
                helperText="Date of the database state on which the ARR/MRR will be calculated"
                label="State date"
                control={control}
                name="stateDate"
              />
            </Grid>

            <Grid item xs={12}>
              <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                <LoadingButton
                  variant="contained"
                  loading={progress != null && progress < 1.0}
                  loadingIndicator={(progress ? progress * 100.0 : 0).toFixed(1) + "%"}
                  onClick={handleSubmit(submitExport)}
                >
                  Generate export
                </LoadingButton>
              </ButtonGroup>
            </Grid>

            {(finalAccountsData || finalContractsData) && (
              <Grid item xs={12}>
                <Typography variant="h5">Download CSV</Typography>

                <ButtonGroup color="primary" aria-label="outlined primary button group">
                  {finalAccountsData && (
                    <CsvDownloader
                      datas={ensureEscapedStrings(finalAccountsData)}
                      wrapColumnChar={'"'}
                      filename={`${formatISO(new Date(), { representation: "complete" })}-accounts.csv`}
                    >
                      <Button startIcon={<CloudDownloadIcon />}>Accounts</Button>
                    </CsvDownloader>
                  )}
                  {finalContractsData && (
                    <CsvDownloader
                      datas={ensureEscapedStrings(finalContractsData)}
                      wrapColumnChar={'"'}
                      filename={`${formatISO(new Date(), { representation: "complete" })}-contracts.csv`}
                    >
                      <Button startIcon={<CloudDownloadIcon />}>Contracts</Button>
                    </CsvDownloader>
                  )}
                </ButtonGroup>
              </Grid>
            )}
          </Grid>
        </Box>
      </ContentBox>
    </>
  );
}

function ExportRecognizedRevenue(props: {}): JSX.Element {
  const { addNotification } = useNotifications();
  const today: Date = new Date();
  const tomorrow: Date = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const { control, handleSubmit } = useForm<ExportRecognizedRevenueFormFields>({
    defaultValues: {
      startDate: formatISO(today, { representation: "date" }),
      endDate: formatISO(tomorrow, { representation: "date" }),
    },
  });
  const [progress, setProgress] = useState<number | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);
  const { exportPeriod: exportPeriodContracts } =
    useServiceResourceExportPeriod<ExportedRecognizedRevenue>(`recognized-revenue`);
  const { exportPeriod: exportPeriodAccounts } =
    useServiceResourceExportPeriod<ExportedRecognizedRevenueAccount>(`recognized-revenue`);
  const [finalContracts, setFinalContracts] = useState<ExportedRecognizedRevenue[] | null>(null);
  const [finalAccounts, setFinalAccounts] = useState<ExportedRecognizedRevenueAccount[] | null>(null);

  const submitExport: SubmitHandler<ExportRecognizedRevenueFormFields> = async (
    formFields: ExportRecognizedRevenueFormFields
  ) => {
    const contractsPagesCounter: Counter = [0, 1];
    const accountsPagesCounter: Counter = [0, 1];
    const accountsData: ExportedRecognizedRevenueAccount[] = [];
    const contractsData: ExportedRecognizedRevenue[] = [];

    setFinalContracts(null);
    setFinalAccounts(null);
    setProgress(0.0);

    const updateProgress = () => {
      const progress = computeTotalProgress(contractsPagesCounter, accountsPagesCounter);
      setProgress(progress == 1.0 ? null : progress);
      if (progress == 1.0) {
        addNotification({
          level: "success",
          text: "All files generated successfully",
        });
      }
    };

    exportPeriodContracts(
      formFields.startDate,
      formFields.endDate,
      "contracts",
      async (objects: ExportedRecognizedRevenue[], final, page, pages) => {
        contractsPagesCounter[1] = pages;
        objects.forEach((obj) => {
          contractsData.push(obj);
        });
        contractsPagesCounter[0] = page;
        updateProgress();
        if (final) {
          setFinalContracts(contractsData);
          addNotification({
            level: "info",
            text: "Recognized revenue export ready, use the button to download the file",
          });
        }
      }
    );
    exportPeriodAccounts(
      formFields.startDate,
      formFields.endDate,
      "accounts",
      async (objects: ExportedRecognizedRevenueAccount[], final, page, pages) => {
        accountsPagesCounter[1] = pages;
        objects.forEach((obj) => {
          accountsData.push(obj);
        });
        accountsPagesCounter[0] = page;
        updateProgress();
        if (final) {
          setFinalAccounts(accountsData);
          addNotification({
            level: "info",
            text: "Recognized revenue accounts export ready, use the button to download the file",
          });
        }
      }
    );
  };

  return (
    <ContentBox header="Export recognized revenue data">
      {errors && <ValidationErrors title="Export error" errors={errors} />}

      <Box component="form" onSubmit={handleSubmit(submitExport)} sx={{ mt: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={7}>
            <DatePicker
              disabled={progress != null}
              rules={{
                required: true,
              }}
              label="Start Date"
              control={control}
              name="startDate"
            />
          </Grid>
          <Grid item xs={7}>
            <DatePicker
              disabled={progress != null}
              rules={{
                required: true,
              }}
              label="End Date"
              control={control}
              name="endDate"
            />
          </Grid>

          <Grid item xs={12}>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              <LoadingButton
                variant="contained"
                loading={progress != null && progress < 1.0}
                loadingIndicator={(progress ? progress * 100.0 : 0).toFixed(1) + "%"}
                type="submit"
              >
                Generate export
              </LoadingButton>
            </ButtonGroup>
          </Grid>

          {(finalContracts || finalAccounts) && (
            <Grid item xs={12}>
              <Typography variant="h5">Download CSV</Typography>

              <ButtonGroup color="primary" aria-label="outlined primary button group">
                {finalAccounts && (
                  <CsvDownloader
                    datas={ensureEscapedStrings(finalAccounts)}
                    wrapColumnChar={'"'}
                    filename={`${formatISO(new Date(), { representation: "complete" })}-recognized-revenue.csv`}
                  >
                    <Button startIcon={<CloudDownloadIcon />}>Accounts</Button>
                  </CsvDownloader>
                )}
                {finalContracts && (
                  <CsvDownloader
                    datas={ensureEscapedStrings(finalContracts)}
                    wrapColumnChar={'"'}
                    filename={`${formatISO(new Date(), { representation: "complete" })}-recognized-revenue.csv`}
                  >
                    <Button startIcon={<CloudDownloadIcon />}>Contracts</Button>
                  </CsvDownloader>
                )}
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
      </Box>
    </ContentBox>
  );
}

function ExportActiveSubscriptions(props: {}): JSX.Element {
  const { addNotification } = useNotifications();
  const [progress, setProgress] = useState<number | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string[] } | null>(null);
  const { exportDirect } = useServiceResourceExportDirect<ExportedActiveSubscription>(`active-subscriptions`);
  const [finalActiveSubscriptions, setFinalActiveSubscriptions] = useState<ExportedActiveSubscription[] | null>(null);

  const generateExport = async () => {
    const activeSubsPagesCounter: Counter = [0, 1];
    const activeSubsData: ExportedActiveSubscription[] = [];
    setFinalActiveSubscriptions(null);
    setProgress(0.0);

    const updateProgress = () => {
      const progress = computeTotalProgress(activeSubsPagesCounter);
      setProgress(progress == 1.0 ? null : progress);
      if (progress == 1.0) {
        addNotification({
          level: "success",
          text: "All files generated successfully",
        });
      }
    };

    exportDirect(async (objects: ExportedActiveSubscription[], final, page, pages) => {
      activeSubsPagesCounter[1] = pages;
      objects.forEach((obj) => activeSubsData.push(obj));
      activeSubsPagesCounter[0] = page;
      updateProgress();
      if (final) {
        setFinalActiveSubscriptions(activeSubsData);
        addNotification({
          level: "info",
          text: "Active subscriptions export ready, use the button to download the file",
        });
      }
    });
  };

  return (
    <ContentBox header="Export active subscriptions data">
      {errors && <ValidationErrors title="Export error" errors={errors} />}

      <Box sx={{ mt: 3 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
              <LoadingButton
                variant="contained"
                loading={progress != null && progress < 1.0}
                loadingIndicator={(progress ? progress * 100.0 : 0).toFixed(1) + "%"}
                onClick={generateExport}
              >
                Generate export
              </LoadingButton>
            </ButtonGroup>
          </Grid>

          {finalActiveSubscriptions && (
            <Grid item xs={12}>
              <Typography variant="h5">Download CSV</Typography>

              <ButtonGroup color="primary" aria-label="outlined primary button group">
                <CsvDownloader
                  datas={ensureEscapedStrings(finalActiveSubscriptions)}
                  wrapColumnChar={'"'}
                  filename={`${formatISO(new Date(), { representation: "complete" })}-active-subscriptions.csv`}
                >
                  <Button startIcon={<CloudDownloadIcon />}>Active Subscriptions</Button>
                </CsvDownloader>
              </ButtonGroup>
            </Grid>
          )}
        </Grid>
      </Box>
    </ContentBox>
  );
}

export function Export(props: {}): JSX.Element {
  return (
    <>
      <ExportARRMRR />

      <ExportRecognizedRevenue />

      <ExportActiveSubscriptions />
    </>
  );
}
